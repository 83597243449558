import React, { useContext, useEffect } from "react"
import { WishlistContext } from "../context/wishlistContext"
import styled from "styled-components"
import { Trash2 } from "react-feather"

const RemoveFromWishlist = ({ product }) => {
  const { wishlist, setWishlist } = useContext(WishlistContext)
  function removeProduct(product) {
    const a = [...wishlist]
    const b = a.filter(item => item.uid !== product.uid)
    setWishlist(b)
    localStorage.setItem("wishlist", JSON.stringify(b))
    
    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push("event", "removed_product")
    }
    
  }
  return (
    <Button onClick={() => removeProduct(product)}>
      <Trash2 size={16} />
    </Button>
  )
}

export default RemoveFromWishlist

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 4px;
  border: 0;
  height: 36px;
  width: 36px;
  &:hover {
    background-color: #efefef;
  }
`
