import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { Layout } from "../components/layout"
import { Col, Container, Row } from "react-grid-system"
import { WishlistContext } from "../context/wishlistContext"
import { Display, Heading } from "../components/typography"
import RemoveFromWishlist from "../components/removeFromWishlist"
import ItemDetails from "../components/itemDetails"
import styled from "styled-components"
import Img from "gatsby-image"
import SEO from "../components/seo"
import DesignTokens from "../components/designTokens"
import { Plus, Minus } from "react-feather"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Wishlist = () => {
  const { wishlist, setWishlist } = useContext(WishlistContext)
  const [userType, setUserType] = useState("Privato")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  function addQuantity(product) {
    setWishlist(prevState =>
      prevState.map(el =>
        el.uid === product.uid ? { ...el, quantity: product.quantity + 1 } : el
      )
    )
    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push("event", "added_quantity")
    }
  }
  function removeQuantity(product) {
    setWishlist(prevState =>
      prevState.map(el =>
        el.uid === product.uid ? { ...el, quantity: product.quantity - 1 } : el
      )
    )
    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push("event", "removed_quantity")
    }
  }

  const { executeRecaptcha } = useGoogleReCaptcha();

    

  const sendWishlist = async(event) => {
    const result = await executeRecaptcha("dynamicAction");
    event.preventDefault()
    setLoading(true)
    const data = {
      wishlist: wishlist,
      user: {
        userType: userType,
        name: name,
        email: email,
        company: company,
        phone: phone,
        subscribedAt: new Date().toISOString(),
      },
    }
    if(result)axios
      .post("/.netlify/functions/sendWishlist", data)
      .then(function (response) {
        setSuccess(true)
        setLoading(false)
        if (typeof window !== "undefined" && window.dataLayer !== undefined) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push("event", "form_submit_wishlist")
        }
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist))
  }, [wishlist])

  return (
    <Layout>
      <SEO title="Wishlist" />
      <StyledContainer>
        <Title>
          <Col>
            <Display color={DesignTokens.colors.primary[500]}>Wishlist</Display>
          </Col>
        </Title>
        {wishlist.length > 0 ? (
          <Row>
            <Col lg={8} md={7}>
              <Table>
                <thead>
                  <tr>
                    <td>Immagine</td>
                    <td>Prodotto</td>
                    <td>Codice</td>
                    <td>Finitura</td>
                    <td>Quantità</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {wishlist.map(product => (
                    <Product>
                      <td>
                        {product.item.image && (
                          <Img fluid={product.item.image.fluid} />
                        )}
                      </td>
                      <td width="300">
                        <p>{product.product.name}</p>
                      </td>
                      <td>
                        <p>{product.item.uid}</p>
                      </td>
                      <td>
                        <p>{product.variant.name}</p>
                      </td>
                      <td>
                        <Button
                          disabled={product.quantity > 1 ? false : true}
                          onClick={() => removeQuantity(product)}
                        >
                          <Minus size={16} />
                        </Button>
                        <Quantity>{product.quantity}</Quantity>
                        <Button onClick={() => addQuantity(product)}>
                          <Plus size={16} />
                        </Button>
                      </td>
                      <td>
                        <RemoveFromWishlist product={product} />
                      </td>
                    </Product>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col lg={4} md={5}>
              <Wrapper id="form">
                <Heading>Richiesta d'offerta</Heading>
                <form onSubmit={sendWishlist}>
                  <section>
                    <label htmlFor="name">Nome e cognome *</label>
                    <input
                      onChange={e => setName(e.target.value)}
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      required
                    />
                  </section>
                  <section>
                    <label htmlFor="userType">Tipologia *</label>
                    <select
                      value={userType}
                      onChange={e => setUserType(e.target.value)}
                      name="userType"
                      id="userType"
                    >
                      <option value="Privato">Privato</option>
                      <option value="Ente pubblico">Ente pubblico</option>
                      <option value="Magazzino edile">Magazzino edile</option>
                      <option value="Rivenditore">Rivenditore</option>
                      <option value="Impresa edile">Impresa edile</option>
                      <option value="Giardiniere">Giardiniere</option>
                      <option value="Progettista">Progettista</option>
                      <option value="Installatore">Installatore</option>
                    </select>
                  </section>
                  {userType !== "Privato" && userType !== "Giardiniere" && (
                    <section>
                      <label htmlFor="company">Azienda *</label>
                      <input
                        onChange={e => setCompany(e.target.value)}
                        type="text"
                        name="company"
                        id="company"
                        value={company}
                        required
                      />
                    </section>
                  )}
                  <section>
                    <label htmlFor="email">Email *</label>
                    <input
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      required
                    />
                  </section>
                  <section>
                    <label htmlFor="phone">Telefono</label>
                    <input
                      onChange={e => setPhone(e.target.value)}
                      type="phone"
                      name="phone"
                      id="phone"
                      value={phone}
                    />
                  </section>
                  <section>
                    <p>
                      Dopo aver preso visione della presente informativa sulla
                      privacy ai sensi dell'ART.13 D.Lgs. n.196 del 2003, a)
                      Acconsento al trattamento dei dati personali al fine
                      esclusivo di avere informazioni riguardo i servizi
                      relativi al presente sito web.
                    </p>
                  </section>
                  <Checkbox>
                    <input
                      type="checkbox"
                      id="privacy"
                      name="privacy"
                      required
                    />
                    <label htmlFor="privacy">Si, acconsento</label>
                  </Checkbox>
                  <section>
                    {!success && (
                      <input
                        type="submit"
                        value={loading ? `Caricamento...` : `Invia richiesta`}
                      />
                    )}
                    {success && (
                      <input
                        type="submit"
                        value="Richiesta inviata!"
                        disabled
                      />
                    )}
                  </section>
                </form>
              </Wrapper>
            </Col>
          </Row>
        ) : (
          <Ad>
            <h2>Nessun prodotto nella wishlist...</h2>
            <p>Aggiungi i prodotti per richiedere un'offerta personalizzata</p>
          </Ad>
        )}
      </StyledContainer>
    </Layout>
  )
}

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 4px;
  border: 0;
  height: 36px;
  width: 36px;
  &:hover {
    background-color: #efefef;
  }
`
const Title = styled(Row)`
  margin-bottom: 3rem;
`

const StyledContainer = styled(Container)`
  margin-top: 4rem;
`
const Ad = styled.div`
  width: 100%;
  height: 400px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: center;
  text-align: center;

  background: ${DesignTokens.colors.white};
  border: 1px solid ${DesignTokens.colors.primary[500]};
  p {
    text-decoration: none;
    font-size: 20px;
  }
  h2 {
    color: ${DesignTokens.colors.primary[500]};
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 15px;
  }
`

const Product = styled.tr`
  width: 100%;
  font-size: ${DesignTokens.fontSizes[3]};
  .gatsby-image-wrapper {
    width: 100px;
    height: 100px;
  }
`

const Quantity = styled.p`
  min-width: 1.5rem;
  text-align: center;
  display: inline-block;
`

const Table = styled.table`
  overflow: auto;
  thead {
    font-weight: bold;
  }
`

const Wrapper = styled.div`
  margin-top: 1rem;
  border-radius: ${DesignTokens.borderRadius};
  border: 1px solid #efefef;
  background-color: #fafafa;
  padding: 32px;
  margin-bottom: 6rem;
  form {
    margin-top: 2rem;
    label {
      margin-bottom: 0.25rem;
      color: black;
      font-size: 14px;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[3]};
      color: black;
      border: none;
      font-size: 14px;
      background: transparent;
      border-bottom: 1px solid ${DesignTokens.colors.primary[400]};
      &:hover,
      &:focus {
        border-bottom: 1px solid ${DesignTokens.colors.tertiary[500]};
      }
    }

    input[type="submit"] {
      margin-top: 1rem;
      background: ${DesignTokens.colors.primary[500]};
      border: none;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 1rem;
      padding-bottom: 1rem;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${DesignTokens.colors.tertiary[500]};
      }
      &:disabled {
        background: #4caf50;
        cursor: default;
      }
    }

    select {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.5em;
      border: 1px solid #ccc;
      border-radius: 0;
      &:focus,
      &:active {
        outline: 0;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        font-size: 12px;
        color: ${DesignTokens.colors.primary[200]};
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

export default Wishlist
